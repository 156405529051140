import * as Sentry from "@sentry/react";

if (process.env?.APP_PRODUCTION && process.env?.SENTRY_DSN) {
    Sentry.init({
        dsn: process.env.SENTRY_DSN,
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.browserProfilingIntegration(),
            Sentry.replayIntegration({
                // NOTE: This will disable built-in masking. Only use this if your site has no sensitive data, or if you've already set up other options for masking or blocking relevant data, such as 'ignore', 'block', 'mask' and 'maskFn'.
                maskAllText: false,
                blockAllMedia: false,
                maskAllInputs: false,
            }),
        ],
        // Tracing
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
        // Set profilesSampleRate to 1.0 to profile every transaction.
        // Since profilesSampleRate is relative to tracesSampleRate,
        // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
        // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
        // results in 25% of transactions being profiled (0.5*0.5=0.25)
        profilesSampleRate: 1.0,
        // Session Replay
        replaysSessionSampleRate: parseFloat(process.env.SENTRY_REPLAY_RATE) ?? 1.0,
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        release: process.env.COMMIT_HASH,
        environment: process.env.SENTRY_ENVIRONMENT,
    });

    if (document.currentScript.dataset.user) {
        const [username, email] = document.currentScript.dataset.user.split(";");
        Sentry.setUser({ email, username });
    }
}
